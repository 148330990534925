/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyIdentityBrokerApi",
            "endpoint": "https://ajycev4iyb.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:78dae491-2d57-4d43-a8f2-0ae96c8e9274",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ggGsjlGs6",
    "aws_user_pools_web_client_id": "2af35i9b79ha1crb1tfukn6q1t",
    "oauth": {
        "domain": "wave-identity-broker-qa.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d11pod24no1fpl.cloudfront.net",
        "redirectSignOut": "https://d11pod24no1fpl.cloudfront.net/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "broker--host-qa",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d11pod24no1fpl.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyIdentityBrokerClients-qa",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerCodes-qa",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerInstance-qa",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerRegistrationCodes-qa",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerUser-qa",
            "region": "us-east-1"
        },
        {
            "tableName": "amplifyIdentityBrokerUserInstance-qa",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
